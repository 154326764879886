import * as React from "react"
import { Suspense } from "react"
import { ApolloProvider } from "@apollo/client"
import { ChakraProvider, useColorMode } from "@chakra-ui/react"
import type { NextPage } from "next"
import type { AppProps } from "next/app"
import { Poppins } from "next/font/google"
import Head from "next/head"
import "../../public/styles.css"
import "../styles/globals.css"

import { useApollo } from "lib/apollo/client"
import { theme } from "lib/theme"
import { GlobalDropContext } from "lib/context/globalDrop"

const poppins = Poppins({
  subsets: ["latin"],
  preload: true,
  weight: ["300", "400", "500", "600", "700", "800", "900"],
  variable: "--font-poppins",
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: any
}

export default function NextApp(props: AppPropsWithLayout) {
  const { Component, pageProps } = props
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  const [isGlobalDropDisabled, setIsGlobalDropDisabled] = React.useState<boolean>(false)
  const value = React.useMemo(
    () => ({ isGlobalDropDisabled, setIsGlobalDropDisabled }),
    [isGlobalDropDisabled],
  )

  return (
    <Suspense>
      <div className={poppins.variable}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <ChakraProvider theme={theme}>
          <DarkModeContainer>
            <ApolloProvider client={apolloClient}>
              <GlobalDropContext.Provider value={value}>
                {getLayout(<Component {...pageProps} />)}
              </GlobalDropContext.Provider>
            </ApolloProvider>
          </DarkModeContainer>
        </ChakraProvider>
      </div>
    </Suspense>
  )
}

function DarkModeContainer(props: { children: React.ReactNode }) {
  const { colorMode } = useColorMode()
  return <div className={colorMode === "dark" ? "dark" : ""}>{props.children}</div>
}
