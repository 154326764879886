import type {
  IncomeDataTaskFragment,
  ReportTaskFragment,
  SignableFormTaskFragment,
  UserTaskEntityFragment,
} from "lib/graphql"

export interface TaskItem {
  type: "vat" | "signableForm" | "report" | "incomeData"
  entity?: UserTaskEntityFragment
  signableForm?: SignableFormTaskFragment
  report?: ReportTaskFragment
  incomeData?: IncomeDataTaskFragment
}

export function gatherTasks(
  entities?: UserTaskEntityFragment[],
  signableForms?: SignableFormTaskFragment[],
): TaskItem[] {
  const tasks: TaskItem[] = []
  entities?.map((entity) => {
    if (entity.hasUpcomingVatDeadline) {
      tasks.push({
        entity,
        type: "vat",
      })
    }
    entity.pendingIncomeData.map((incomeData) => {
      tasks.push({
        entity,
        type: "incomeData",
        incomeData,
      })
    })
    entity.pendingReports.map((report) => {
      tasks.push({
        entity,
        type: "report",
        report: report,
      })
    })
  })
  signableForms?.map((signableForm) => {
    tasks.push({
      entity: signableForm.entity ? signableForm.entity : undefined,
      type: "signableForm",
      signableForm: signableForm,
    })
  })
  return tasks
}
