import * as React from "react"
import type { DropzoneOptions, FileRejection } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import { FiFile, FiPlus, FiTrash } from "react-icons/fi"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  BoxProps,
  HStack,
  Stack,
  Input as CInput,
  Box,
  Center,
  Flex,
  FormControl,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"
import Compressor from "compressorjs"
import * as path from "path"

import type { CustomFile } from "lib/helpers/isValidFile"
import { DEFAULT_ACCEPTED_FILETYPES, isValidFile } from "lib/helpers/isValidFile"
import { useFormContext } from "lib/hooks/useForm"
import { useToast } from "lib/hooks/useToast"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { InputError } from "./InputError"
import { InputLabel } from "./InputLabel"
import { CustomHeader } from "./DateInput"
import dayjs from "dayjs"
import { CalendarIcon } from "@chakra-ui/icons"

export type DateFile = {
  date: Date
  file: File | string
}

interface Props extends Omit<BoxProps, "onDrop"> {
  name: string
  label?: string
  isRequired?: boolean
  isDisabled?: boolean
  subLabel?: string
  dropzoneOptions?: Omit<DropzoneOptions, "multiple" | "onDrop">
  acceptAllFiletypes?: boolean
  customFiletypes?: string[]
}

export function MultiDateFileInput({
  name,
  label,
  subLabel,
  isDisabled,
  isRequired,
  dropzoneOptions,
  acceptAllFiletypes = false,
  customFiletypes,
  ...props
}: Props) {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const bt = useBetterTranslation()
  const toast = useToast()
  const fieldError = errors?.[name]

  React.useEffect(() => {
    register(name)
  }, [register, name])

  const dateFiles = watch(name) as DateFile[]

  const onDrop = React.useCallback(
    async (newFiles: CustomFile[], rejectedFiles: FileRejection[]) => {
      if (newFiles.length === 0) return

      type ProcessedDateFile = { date: Date; file: CustomFile | Blob }
      const processedDateFiles: ProcessedDateFile[] = []

      await Promise.all(
        newFiles.map(async (file) => {
          const ext = file.path ? path.parse(file.path).ext : undefined

          if (!isValidFile(file, bt, toast, dropzoneOptions?.maxSize, acceptAllFiletypes, customFiletypes))
            return

          // Catch any other rejections
          if (rejectedFiles.length > 0) {
            toast({ status: "error", description: bt({ en: "Invalid file", nl: "Ongeldig bestand" }) })
            return
          }

          let date = new Date()
          if (file instanceof File) {
            // console.log("----------- MUFFIN -----------")
            date = new Date(file.lastModified)
            // console.log(date)
          } else {
            console.log("----------- ONIONNN -----------")
          }

          if (ext && DEFAULT_ACCEPTED_FILETYPES.images.includes(ext)) {
            await new Promise<void>((resolve, reject) => {
              new Compressor(file, {
                quality: 0.2, // not recommended to go below 0.6, but have tested and the quality of 0.2 should be good enough
                success(compressedFile) {
                  clearErrors(name)
                  processedDateFiles.push({ date, file: compressedFile })
                  resolve()
                },
                error(e) {
                  Sentry.captureException(`MultiFileInput Image compression error: ${e}`)
                  console.log(e.message)
                  reject()
                },
              })
            })
          } else {
            clearErrors(name)
            processedDateFiles.push({ date, file })
          }
        }),
      )
      setValue(name, [...dateFiles, ...processedDateFiles], { shouldDirty: true })
    },
    [toast, dropzoneOptions, dateFiles, name, setValue, clearErrors, bt, acceptAllFiletypes, customFiletypes],
  )

  // React.useEffect(() => {
  //   console.log(dateFiles)
  // }, [dateFiles])

  const { getRootProps, getInputProps } = useDropzone({ ...dropzoneOptions, onDrop, multiple: true })

  const borderColor = useColorModeValue("gray.200", "gray.600")
  const bgColor = useColorModeValue("gray.50", "gray.700")
  const inputBgColor = useColorModeValue("white", "gray.800")
  const textColor = useColorModeValue("gray.800", "gray.200")

  const handleDateChange = (i: number, date: Date | null) => {
    if (!date) return
    console.log(i, date)
    const updatedDateFiles = dateFiles.map((dateFile, index) =>
      index === i ? { ...dateFile, date } : dateFile,
    )
    setValue(name, updatedDateFiles, { shouldDirty: true })
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={!!fieldError}>
      <InputLabel label={label} subLabel={subLabel} name={name} />
      <Center
        w="100%"
        h="300px"
        rounded="sm"
        overflow="hidden"
        border="1px solid"
        borderColor={!!fieldError ? "red.500" : borderColor}
        textAlign="center"
        color="gray.500"
        bg={bgColor}
        {...props}
      >
        {dateFiles && dateFiles.length > 0 ? (
          <Stack p={4} h="100%" w="100%" overflowY="scroll">
            <Box {...getRootProps()} outline={isDisabled ? "none" : undefined} w="100%">
              <input disabled={isDisabled} {...getInputProps()} />
              <IconButton
                aria-label="add another upload"
                icon={<Box as={FiPlus} boxSize="22px" />}
                size="sm"
                colorScheme="pink"
              />
            </Box>
            <Stack>
              {dateFiles.map((dateFile, i) => (
                <Flex
                  key={`${(dateFile.file as File).name}-${i}`}
                  p={2}
                  bg={inputBgColor}
                  align="center"
                  marginLeft={0}
                  justifyContent="space-between"
                  boxShadow="sm"
                  borderRadius="sm"
                  w="100%"
                >
                  <Flex alignItems="center" overflow="hidden">
                    <Box as={FiFile} boxSize="16px" color="pink.500" mx={2} />
                    <Text fontSize="sm" noOfLines={1} color={textColor} pr={2} textAlign="left">
                      {typeof dateFile.file === "string"
                        ? dateFile.file.split("/").pop()
                        : dateFile.file?.name}
                    </Text>
                  </Flex>

                  <HStack>
                    <Box w="150px">
                      <DatePicker
                        withPortal
                        dateFormat="dd/MM/yyyy"
                        dropdownMode="select"
                        showPopperArrow={false}
                        fixedHeight
                        selected={dateFile.date}
                        onChange={(date) => handleDateChange(i, date)}
                        renderCustomHeader={(props) => <CustomHeader {...props} />}
                        customInput={
                          <FormControl isInvalid={!!fieldError} mb={0}>
                            <InputGroup cursor="pointer">
                              <CInput
                                mb={0}
                                w="100%"
                                cursor="pointer"
                                placeholder={dayjs(dateFile.date).format("DD/MM/YYYY")}
                              />
                              <InputRightElement>
                                <CalendarIcon />
                              </InputRightElement>
                            </InputGroup>
                            <InputError error={fieldError} />
                          </FormControl>
                        }
                      />
                    </Box>
                    <Tooltip
                      label={bt({ en: "Remove file", nl: "Verwijder bestand" })}
                      aria-label="Remove file"
                    >
                      <IconButton
                        aria-label="remove file"
                        icon={<Box as={FiTrash} />}
                        onClick={() =>
                          setValue(
                            name,
                            (dateFiles as Array<any>).filter(
                              (_, i) =>
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                i !== dateFiles.indexOf(dateFile),
                            ),
                            { shouldDirty: true },
                          )
                        }
                        colorScheme="red"
                        size="sm"
                        variant="ghost"
                        borderRadius="sm"
                      />
                    </Tooltip>
                  </HStack>
                </Flex>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Center
            {...getRootProps()}
            _hover={{ opacity: 0.9 }}
            outline={isDisabled ? "none" : undefined}
            cursor={isDisabled ? "default" : "pointer"}
            color="gray.500"
            w="100%"
            h="100%"
          >
            <input disabled={isDisabled} {...getInputProps()} />
            {bt({
              en: "Drag or click here to upload file",
              nl: "Sleep of klik hier om een bestand te uploaden",
            })}
          </Center>
        )}
      </Center>
      <InputError error={fieldError} />
    </FormControl>
  )
}
